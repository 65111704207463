import { track, init } from "@amplitude/analytics-browser"
import { getMetaContent, getMetaContentMaybe } from "./get-meta-content"

const RAILS_ENV = getMetaContent("RAILS_ENV")
const AMPLITUDE_API_KEY = getMetaContentMaybe("AMPLITUDE_API_KEY")

export const EVENTS = {
  PHONE_NUMBER_ENTERED: "phone_number_entered",
  GET_APP_CLICKED: "get_app_clicked",
  COUNTRY_CODE_CHANGED: "country_code_changed",
  CONSENT_GIVEN: "consent_given",
} as const

// Initialize Amplitude with your API key
export const initializeAnalytics = (): void => {
  if (!AMPLITUDE_API_KEY) {
    console.warn("Amplitude API key not found")
    return
  }

  try {
    init(AMPLITUDE_API_KEY, undefined, {
      logLevel: RAILS_ENV === "production" ? 0 : 2,
      defaultTracking: {
        sessions: true,
        pageViews: true,
        formInteractions: true,
        fileDownloads: true,
      },
    })
  } catch (error) {
    console.error("Error initializing Amplitude:", error)
  }
}

export const trackEvent = (eventName: string, eventProperties?: Record<string, unknown>): void => {
  if (!AMPLITUDE_API_KEY) {
    console.info("Amplitude API key not found; skipping event tracking")
    return
  }

  try {
    track(eventName, eventProperties)
  } catch (error) {
    console.error("Error tracking event:", error)
  }
}
