import { useEffect } from "react"
import { getMetaContent } from "~/common/get-meta-content"
import appStoreBadge from "~/images/store-badges/app-store-badge"
import playStoreBadge from "~/images/store-badges/play-store-badge"
import logo from "~/images/landing/logo"
import { AppText } from "~/ui/app-text"

const APP_STORE_URL = getMetaContent("APP_STORE_URL")
const PLAY_STORE_URL = getMetaContent("PLAY_STORE_URL")

const getRedirectTo = () => {
  const userAgent = window.navigator.userAgent
  if (/iP(hone|ad|od)/.test(userAgent)) {
    return APP_STORE_URL
  } else if (/Android/.test(userAgent)) {
    return PLAY_STORE_URL
  }
  return null
}

export const DownloadScreen = () => {
  useEffect(() => {
    const redirectTarget = getRedirectTo()
    if (redirectTarget) {
      window.location.href = redirectTarget
    }
  }, [])

  return (
    <div className="relative flex min-h-screen bg-[#2D92FF]" data-testid="download-screen">
      <div className="mx-auto my-auto max-w-7xl px-6 py-8">
        <div className="mx-auto max-w-[440px] space-y-8 text-center">
          {/* Logo section */}
          <div className="flex justify-center">
            <img
              src={logo.src}
              alt="ChatBCC Logo"
              width={logo.width}
              height={logo.height}
              className="h-[104px] w-[257px]"
              loading="eager"
            />
          </div>

          {/* Title and description */}
          <div className="space-y-6">
            <AppText variant="h1" className="text-[#141010]">
              Download ChatBCC
            </AppText>
            <AppText variant="body1" className="text-[#141010]">
              Join amazing conversations about topics you love. Available on iOS and Android.
            </AppText>
          </div>

          {/* Store badges */}
          <div className="flex flex-row items-center justify-center gap-3">
            <a
              href={APP_STORE_URL}
              className="transition-transform hover:scale-105 active:scale-95"
              aria-label="Download on App Store"
            >
              <img
                src={appStoreBadge.src}
                alt="Download on App Store"
                height={60}
                className="h-[60px] object-contain xs:pl-[23px]"
              />
            </a>
            <a
              href={PLAY_STORE_URL}
              className="transition-transform hover:scale-105 active:scale-95"
              aria-label="Get it on Google Play"
            >
              <img
                src={playStoreBadge.src}
                alt="Get it on Google Play"
                height={60}
                className="h-[60px] object-contain"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
