import "./landing.css"
import type { EmblaOptionsType } from "embla-carousel"
import useEmblaCarousel from "embla-carousel-react"
import Autoplay from "embla-carousel-autoplay"
import logo from "~/images/landing/logo.ts"
import star from "~/images/landing/star.ts"
import title from "~/images/landing/title.ts"
import caretDown from "~/images/landing/caret-down.ts"
import bachelor from "~/images/landing/carousel/bachelor"
import gameday from "~/images/landing/carousel/gameday"
import marketMovers from "~/images/landing/carousel/market-movers"
import { Link } from "~/ui/link"
import { useMediaQuery } from "~/hooks/use-media-query"
import { AppText } from "~/ui/app-text"
import { useState } from "react"
import { trackEvent, EVENTS } from "~/common/analytics"

const FINE_PRINT_CLASSES = "text-[#141010]/70 text-sm leading-tight"

//TODO: Bring back after approval
//const oldText = "By clicking 'Get The App', you consent to receive text messages from ChatBCC. Text messages may be autodialed, and data rates may apply. You also agree to our"

const TermsText = ({ className }: { className?: string }) => (
  <AppText variant="caption" className={FINE_PRINT_CLASSES + " " + className}>
    By using ChatBCC, you agree to our{" "}
    <Link className="underline" to={"../terms"}>
      Terms of Use
    </Link>{" "}
    &{" "}
    <Link className="underline" to={"../privacy"}>
      Privacy Policy.
    </Link>
  </AppText>
)

const ConsentText = ({ className }: { className?: string }) => (
  <AppText variant="caption" className={FINE_PRINT_CLASSES + " " + className}>
    By clicking "Get The App", you consent to receive text messages from ChatBCC. Message frequency
    varies. Text messages may be autodialed, and message & data rates may apply. Reply HELP for
    help, STOP to cancel. You also agree to our{" "}
    <Link className="underline" to={"../terms"}>
      Terms of Use
    </Link>{" "}
    &{" "}
    <Link className="underline" to={"../privacy"}>
      Privacy Policy.
    </Link>
  </AppText>
)

interface ConsentCheckboxProps {
  checked: boolean
  onChange: (checked: boolean) => void
  className?: string
  highlight?: boolean
}

const ConsentCheckbox = ({ checked, onChange, className, highlight }: ConsentCheckboxProps) => (
  <div
    className={`flex items-start gap-3 rounded-lg bg-white/10 p-4 ${className} ${
      highlight ? "animate-pulse ring-2 ring-[#FD905B]" : ""
    }`}
  >
    <input
      type="checkbox"
      id="consent-checkbox"
      checked={checked}
      onChange={(e) => {
        onChange(e.target.checked)
        if (e.target.checked) {
          trackEvent(EVENTS.CONSENT_GIVEN)
        }
      }}
      className="mt-1 h-4 w-4 rounded border-[#141010] bg-white"
      aria-label="Consent to receive messages"
    />
    <label htmlFor="consent-checkbox">
      <ConsentText className="max-sm:hidden" />
      <TermsText className="sm:hidden" />
    </label>
  </div>
)

export const LandingScreen = () => {
  const isLargeScreen = useMediaQuery("(min-width: 1024px)")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [hasConsented, setHasConsented] = useState(false)
  const [highlightConsent, setHighlightConsent] = useState(false)

  const options: EmblaOptionsType = {
    axis: isLargeScreen ? "y" : "x",
    loop: true,
    dragFree: false,
    skipSnaps: false,
    align: "center",
  }

  const [emblaRef] = useEmblaCarousel(options, [
    Autoplay({
      delay: 3500,
      stopOnInteraction: false,
      stopOnMouseEnter: false,
    }),
  ])

  const formatPhoneNumber = (value: string) => {
    // Remove all non-digit characters
    const digits = value.replace(/\D/g, "")

    // Format the number as you type
    let formattedNumber = ""
    if (digits.length === 0) {
      formattedNumber = ""
    } else if (digits.length <= 3) {
      formattedNumber = `(${digits}`
    } else if (digits.length <= 6) {
      formattedNumber = `(${digits.slice(0, 3)}) ${digits.slice(3)}`
    } else {
      formattedNumber = `(${digits.slice(0, 3)}) ${digits.slice(3, 6)}-${digits.slice(6, 10)}`
    }

    return formattedNumber
  }

  // Function to get only the area code from a phone number
  const getAreaCode = (phoneNumber: string): string => {
    const digits = phoneNumber.replace(/\D/g, "")
    return digits.slice(0, 3)
  }

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formattedNumber = formatPhoneNumber(e.target.value)
    setPhoneNumber(formattedNumber)
    if (formattedNumber.length >= 14) {
      trackEvent(EVENTS.PHONE_NUMBER_ENTERED, { areaCode: getAreaCode(formattedNumber) })
    }
  }

  const handleCountryCodeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    trackEvent(EVENTS.COUNTRY_CODE_CHANGED, { countryCode: e.target.value })
  }

  const handleGetAppClick = () => {
    trackEvent(EVENTS.GET_APP_CLICKED)
  }

  const carouselImageClasses = "lg:max-w-[400px] max-lg:mx-auto lg:ml-auto"

  return (
    <div
      className="relative flex min-h-screen overflow-visible bg-[#2D92FF]"
      data-testid="landing-screen"
    >
      {/* Main content */}
      <div className="mx-auto max-w-7xl px-6 py-8 lg:my-auto lg:py-16">
        {/* Decorative stars */}
        <div className="absolute left-[49%] top-[60%] max-sm:hidden">
          <img src={star.src} alt="" className="h-[37px] w-[39px] animate-pulse" />
        </div>
        <div className="absolute left-[79%] top-[40%] hidden">
          <img src={star.src} alt="" className="h-[37px] w-[39px] animate-pulse" />
        </div>

        {/* Content grid */}
        <div className="mx-auto grid max-w-[540px] grid-cols-1 gap-6 lg:max-w-[1080px] lg:grid-cols-2 lg:gap-16">
          {/* Left column */}
          <div className="space-y-4 lg:space-y-6">
            {/* Logo section */}
            <div className="mb-6 flex justify-center lg:mb-24 lg:justify-start">
              <img
                src={logo.src}
                alt="ChatBCC Logo"
                width={logo.width}
                height={logo.height}
                className="h-[50px] w-[124px] lg:h-[69px] lg:w-[171px]"
                loading="eager"
              />
            </div>

            <div className="space-y-3 lg:space-y-8">
              {/* Decorative stars */}
              <div className="absolute">
                <div className="relative -right-[20px] -top-[60px]">
                  <img src={star.src} alt="" className="h-[37px] w-[39px] animate-pulse" />
                </div>
              </div>
              <div className="mb-3 lg:mb-8">
                <img
                  src={title.src}
                  alt="A Front Row Seat to Epic Group Chats"
                  className="h-[180px] w-[400px] sm:h-[210px] sm:w-[470px] lg:h-[245px] lg:w-[540px]"
                  loading="eager"
                />
                {/* Alternative pure text version instead of using an SVG image for the title:

                <div className="text-[min(8vw,_12rem)] sm:text-[min(10vw,_16rem)] font-black tracking-tighter leading-none whitespace-wrap">
                  <span className="text-[#141010] font-extrabold">A FRONT ROW SEAT TO </span>
                  <span className="text-[#FFC700] font-extrabold [-webkit-text-stroke:2px_#141010] [text-stroke:2px_#141010]">
                    EPIC GROUP CHATS
                  </span>
                </div>
                */}
              </div>
              <AppText
                variant={isLargeScreen ? "body1" : "body2"}
                className="max-w-[540px] text-[#141010]"
              >
                Amazing people having real conversations about topics you love. Follow your
                favorites or start your own.
              </AppText>
            </div>

            {/* Phone input section */}
            <div className="grid grid-cols-1 gap-2 pt-6 max-lg:max-w-[540px] sm:grid-cols-2 lg:gap-4">
              <div className="flex h-[48px] items-center rounded-lg border border-[#141010] bg-[#FCFFF9] max-sm:hidden">
                <AppText variant="button" className="ml-4 flex w-full items-center text-18">
                  <select
                    className="flex h-[27px] w-[135px] cursor-pointer appearance-none items-center gap-1 rounded-full bg-[#E8E8E4] px-2 leading-none"
                    defaultValue="US"
                    onChange={handleCountryCodeChange}
                  >
                    <option value="US">🇺🇸 +1</option>
                    <option value="CA">🇨🇦 +1</option>
                    <option value="GB">🇬🇧 +44</option>
                    <option value="FR">🇫🇷 +33</option>
                    <option value="DE">🇩🇪 +49</option>
                    <option value="IT">🇮🇹 +39</option>
                    <option value="ES">🇪🇸 +34</option>
                    <option value="AU">🇦🇺 +61</option>
                    <option value="JP">🇯🇵 +81</option>
                    <option value="CN">🇨🇳 +86</option>
                  </select>
                  <div className="pointer-events-none absolute ml-[65px]">
                    <img src={caretDown.src} alt="dropdown" className="h-[12px] w-[12px]" />
                  </div>
                  <input
                    type="tel"
                    placeholder="(555) 555-1234"
                    value={phoneNumber}
                    onChange={handlePhoneChange}
                    maxLength={14}
                    className="ml-4 w-full border-none bg-transparent text-[#141010] placeholder-[#141010]/50 outline-none"
                    aria-label="Phone number input"
                  />
                </AppText>
              </div>

              <div>
                <Link
                  to={hasConsented ? "../download" : "#"}
                  className={`block ${!hasConsented && "opacity-90"}`}
                  onClick={(e) => {
                    if (!hasConsented) {
                      //setHasConsented(true)
                      e.preventDefault()
                      setHighlightConsent(true)
                      // Remove highlight after animation
                      setTimeout(() => setHighlightConsent(false), 2000)
                      return
                    }
                    handleGetAppClick()
                  }}
                >
                  <button className="flex h-[48px] w-full items-center justify-center rounded-full border border-[#141010] bg-[#FD905B] px-4 py-3 text-[#141010] transition-colors hover:bg-[#FD905B]/90 lg:w-[215px]">
                    <AppText variant="button" className="text-18">
                      <strong>GET THE APP</strong>
                    </AppText>
                  </button>
                </Link>
              </div>
            </div>
            <ConsentCheckbox
              checked={hasConsented}
              onChange={setHasConsented}
              className="max-sm:hidden"
              highlight={highlightConsent}
            />
          </div>

          {/* Right column - Carousel */}
          <div>
            <div className="carousel-mask overflow-hidden max-lg:max-w-[540px]">
              <div className="max-lg:mx-auto max-lg:w-full max-lg:max-w-[540px]">
                <div
                  ref={emblaRef}
                  className="embla max-lg:mx-auto max-lg:max-w-[400px] max-lg:overflow-hidden"
                >
                  <div className="embla__container lg:h-[720px]">
                    <div className="embla__slide">
                      <img
                        src={bachelor.src}
                        alt="The Bachelor chat"
                        className={carouselImageClasses}
                      />
                    </div>
                    <div className="embla__slide">
                      <img src={gameday.src} alt="Gameday chat" className={carouselImageClasses} />
                    </div>
                    <div className="embla__slide">
                      <img
                        src={marketMovers.src}
                        alt="Market Movers chat"
                        className={carouselImageClasses}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <TermsText className="pt-3 text-center sm:hidden" />
          </div>
        </div>
      </div>
    </div>
  )
}
