import logo from "~/images/landing/logo"
import { AppText } from "~/ui/app-text"

export const ComingSoonScreen = (): JSX.Element => {
  return (
    <div className="relative flex min-h-screen bg-[#2D92FF]" data-testid="coming-soon-screen">
      <div className="mx-auto my-auto max-w-7xl px-6 py-8">
        <div className="mx-auto max-w-[440px] space-y-8 text-center">
          {/* Logo section */}
          <div className="flex justify-center">
            <img
              src={logo.src}
              alt="ChatBCC Logo"
              width={logo.width}
              height={logo.height}
              className="h-[130px] w-[321px]"
              loading="eager"
            />
          </div>

          {/* Coming soon text */}
          <div className="space-y-6">
            <AppText variant="h1" className="text-[#141010]">
              Coming Soon
            </AppText>
          </div>
        </div>
      </div>
    </div>
  )
}
